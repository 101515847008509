import React from "react";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { MdConstruction } from "react-icons/md";
import { Helmet } from "react-helmet";

const Resources = () => {
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/pricing",
			title: "pricing",
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>
					Tabin | Resources - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/resources' />
			</Helmet>
			<div className='mt-10'>
				<div className='flex justify-center'>
					<h1 className='text-4xl'>Page Under Construction</h1>
				</div>
				<div className='flex justify-center'>
					<MdConstruction className='w-[300px] h-[300px]' />
				</div>
			</div>
		</>
	);
};

export default Resources;
