import React from "react";
import { Helmet } from "react-helmet";

const TabinExtension = () => {
	return (
		<div className='grid justify-center items-center pt-6'>
			<Helmet>
				<title>
					Tabin | Extensions - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/extensions' />
			</Helmet>
			<div className='flex m-2 p-2 justify-center items-center '>
				<a href='https://chromewebstore.google.com/detail/tabin-schedule-your-day/aikkjpmagfploeaedmhickkcadlfndno?hl=en&authuser=0'>
					<span className='flex h-auto w-auto px-12 justify-center items-center bg-[#AAF0DA] border-2 rounded-full hover:bg-[#48e6b4]'>
						<i className='flex flex-nowrap w-[full] h-[120px] justify-center items-center my-3 drop-shadow-sm opacity-70 hover:opacity-100 hover:drop-shadow-xl'>
							<img className='h-[75px]' src='tabin.png' alt='' />
							<span className='grid justify-center items-center mx-8 text-2xl font-medium'>
								<p>Get</p>
								<p>Tabin for chrome</p>
							</span>
						</i>
					</span>
				</a>
			</div>
			<div className='border-2 p-4 m-4 '>
				<img src='chrome_extension.png' alt='' />
			</div>
		</div>
	);
};

export default TabinExtension;
