import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllUsers,
	updateSingleUser,
	deleteSingleUser,
} from "../features/user/userSlice";
import { GrEdit } from "react-icons/gr";
import DashboardModal from "../components/DashboardModal";

const Dashboard = () => {
	const EMAIL = "ssingh0188@gmail.com";
	const TAG = "Dashboard.";
	console.log(TAG);

	const user = useSelector((state) => state.auth.user);
	const all_users = JSON.parse(localStorage.getItem("all_users"));

	const [usersData, setUsersData] = useState(all_users);
	const [userEmail, setUserEmail] = useState();
	const [dataLoaded, setDataLoaded] = useState(
		JSON.parse(localStorage.getItem("all_users")) ? true : false
	);

	// const [userAdmin, setUserAdmin] = useState();

	const [modalOpen, setModalOpen] = useState(false);
	const [userId, setUserId] = useState();
	const [toggleStatus, setToggleStatus] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getStorageData = () => {
		setUsersData(JSON.parse(localStorage.getItem("all_users")));
	};

	function timeoutAndGetData() {
		setTimeout(() => {
			getStorageData();
		}, 1000);
	}

	const onModalClose = async (userId, toggleStatus) => {
		// get user to update from users Data state
		const editUser = usersData.find((user) => user._id === userId);

		// update admin status
		editUser.admin = toggleStatus;

		// update usersData state
		const data = usersData.map((user) => {
			if (user._id === userId) {
				return {
					...user,
					admin: toggleStatus,
				};
			} else {
				return user;
			}
		});

		// re-set usersData state
		setUsersData(data);

		// update DB
		dispatch(updateSingleUser(editUser));

		// update local storage
		localStorage.setItem("all_users", JSON.stringify(data));

		// closed Modal
		setModalOpen(false);
	};

	function onEditAdminField(usr) {
		setUserId(usr._id);
		setUserEmail(usr.email);
		setToggleStatus(usr.admin);
		setModalOpen(true);
	}

	function deleteBtnClickHandler(usr) {
		console.log("delete usr id: ", usr._id);
		dispatch(deleteSingleUser(usr._id));
		setUsersData((oldUsers) => {
			return oldUsers.filter((user) => user._id !== usr._id);
		});
	}

	useEffect(() => {
		if (!user) {
			navigate("/");
			setDataLoaded(true);
		} else {
			if (!user.admin && user.email !== EMAIL) {
				navigate("/");
			}
		}
		if (
			!dataLoaded &&
			user &&
			(user.admin || user.email === "ssingh0188@gmail.com")
		) {
			dispatch(getAllUsers());
			setDataLoaded(true);
			timeoutAndGetData();
		}
		usersData;
	}, [user, usersData, dispatch]);

	return (
		<>
			{usersData ? (
				<>
					<section>
						<div className='flex justify-center items-center mt-14'>
							<h1 className='text-4xl'>Welcome to Admin Dashboard</h1>
						</div>
						<div className='flex mx-14 mt-14 '>
							<table className='table-auto w-[90%] border-seperate border border-slate-400'>
								<thead>
									<tr>
										<th className='border border-slate-300'>User ID</th>
										<th className='border border-slate-300'>First Name</th>
										<th className='border border-slate-300'>Last Name</th>
										<th className='border border-slate-300'>Email</th>
										<th className='border border-slate-300'>Account Created</th>
										<th className='border border-slate-300'>Admin</th>
										<th className='border border-slate-300'>Paid</th>
										<th className='border border-slate-300'>Delete</th>
									</tr>
								</thead>
								<tbody>
									{usersData.map((usr) => {
										return (
											<>
												<tr>
													<td className='border border-slate-300'>{usr._id}</td>
													<td className='border border-slate-300'>
														{usr.first_name}
													</td>
													<td className='border border-slate-300'>
														{usr.last_name}
													</td>
													<td className='border border-slate-300'>
														{usr.email}
													</td>
													<td className='border border-slate-300'>
														{usr.createdAt}
													</td>
													<td className='border border-slate-300'>
														<div className='flex justify-center items-center'>
															<p className='mr-2'>{String(usr.admin)}</p>
															<GrEdit
																className='pl-[1px] cursor-pointer'
																onClick={() => onEditAdminField(usr)}
															/>
														</div>
													</td>
													<td className='border border-slate-300'>
														{String(usr.paid)}
													</td>
													<td className='flex justify-center items-center border border-slate-100'>
														<button
															className='btn bg-[#f66f6f] my-1 py-1 px-3 hover:bg-red-600'
															onClick={() => deleteBtnClickHandler(usr)}
														>
															Delete
														</button>
													</td>
												</tr>
											</>
										);
									})}
								</tbody>
							</table>
						</div>{" "}
						<DashboardModal
							isOpen={modalOpen}
							onClose={() => onModalClose(userId, toggleStatus)}
							toggleStatus={toggleStatus}
							setToggleStatus={setToggleStatus}
							userEmail={userEmail}
						/>
					</section>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default Dashboard;
