import React from "react";
import { Link } from "react-router-dom";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { Mixpanel } from "../app/mixpanel-instance";

const Footer = () => {
	return (
		<div className='flex w-full h-[full] justify-center items-start bg-[#FF6E8B] border-t-2'>
			<div className='flex justify-center h-[80%] w-[20%] mt-6 pt-5'>
				<i className='flex w-[12%] justify-center items-center text-3xl px-1 drop-shadow-sm opacity-50 hover:opacity-100 hover:drop-shadow-xl'>
					<a href='https://x.com/tabin_io' target='_blank' rel='noreferrer'>
						<FaXTwitter />
					</a>
				</i>
				<i className='flex w-[12%] justify-center items-center text-3xl px-1 drop-shadow-sm opacity-50 hover:opacity-100 hover:drop-shadow-xl'>
					<a
						href='https://www.linkedin.com/company/tabin-io/about/'
						target='_blank'
						rel='noreferrer'
					>
						<FaLinkedin />
					</a>
				</i>
				<i className='flex w-[12%] justify-center items-center text-3xl px-1 drop-shadow-sm opacity-50 hover:opacity-100 hover:drop-shadow-xl'>
					<a
						href='https://www.youtube.com/@Tabin-io'
						target='_blank'
						rel='noreferrer'
					>
						<TfiYoutube />
					</a>
				</i>
			</div>
			<div className='flex h-[80%] w-[80%] justify-evenly items-start p-6'>
				<div className='grid'>
					<p className='text-xl font-semibold'>Legal</p>
					<Link to='/privacy'>
						<p className='text-sm pt-2 text-gray-700 hover:text-black'>
							Privacy
						</p>
					</Link>
					<Link to='/cookies'>
						<p className='text-sm pt-2 text-gray-700 hover:text-black'>
							Cookies
						</p>
					</Link>
				</div>

				{/* <span></span>
				<span></span> */}
			</div>
		</div>
	);
};

export default Footer;
