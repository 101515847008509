import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import Cookie from "js-cookie";
import { register } from "../features/auth/authSlice";
import { getUserTabins } from "../features/tabin/tabinSlice";
import ReactGA from "react-ga4";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

// import { getTabinCenterTabins } from "../features/tabin/tabinSlice";

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID;

const TAG = "Login";
function Login() {
	const [userData, setUserData] = useState([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSuccess = async (res) => {
		console.log(TAG + ".onSuccess");
		console.log("LOGIN SUCCESS! current user: ", res);

		const userData = {
			googleId: res.profileObj.googleId,
			givenName: res.profileObj.givenName,
			familyName: res.profileObj.familyName,
			email: res.profileObj.email,
			imageUrl: res.profileObj.imageUrl,
			admin: false,
			paid: false,
		};

		console.log("userdata", userData);
		console.log("googleid", userData.googleId);
		// register user to backend
		await dispatch(register(userData));
		setUserData(userData);

		// get user tabins
		await dispatch(getUserTabins(userData.googleId));
		// get tabin center page tabins
		// await dispatch(getTabinCenterTabins());

		// Get the access token
		const accessToken = res.tokenObj.access_token;

		// Get tomorrow's date in the required format (ISO 8601)
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		const startOfDay = new Date(tomorrow.setHours(0, 0, 0, 0)).toISOString();
		const endOfDay = new Date(tomorrow.setHours(23, 59, 59, 999)).toISOString();

		// Fetch calendar events for tomorrow
		console.log("hello before");
		try {
			const eventsResponse = await fetch(
				`https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${startOfDay}&timeMax=${endOfDay}&singleEvents=true&orderBy=startTime`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);
			const eventsData = await eventsResponse.json();
			Cookie.set("gevents", JSON.stringify(eventsData.items));
			console.log("Tomorrow's events: ", eventsData);
		} catch (error) {
			console.error("Error fetching calendar events: ", error);
		}

		console.log("hello after");

		let user = JSON.parse(Cookie.get("user"));
		console.log("_id", user._id);
		ReactGA.event({
			category: "User Login",
			action: "Google Account Sign in",
			label: "Google Account Sign in",
			value: res.profileObj.googleId,
		});
		Mixpanel.track("User Login", {
			user: user._id,
		});
		Mixpanel.identify(user._id);
	};

	const { isLoading, isError, isSuccess, isSignedIn, message } = useSelector(
		(state) => state.auth
	);

	const onFailure = (res) => {
		let cookie_distinct_id = Cookie.get("distinct_id");
		Mixpanel.track("User Login failed", {
			user: cookie_distinct_id,
		});
		console.log("LOGIN Failed! current user: ", res);
	};

	useEffect(() => {
		if (isError) {
			console.log(message);
		}
		if (isSignedIn || userData.length !== 0) {
			navigate("/signupflow");
		}

		Mixpanel.track_pageview();

		ReactGA.send({
			hitType: "pageview",
			page: "/login",
			title: "Login",
		});
	}, [
		userData,
		isLoading,
		isError,
		isSuccess,
		isSignedIn,
		message,
		navigate,
		dispatch,
	]);

	return (
		<div>
			<Helmet>
				<title>
					Tabin | Login - Context Switching Made Easy To Increase Your Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/login' />
			</Helmet>
			<div className={`flex sm:mb-8 mb-6 xs:p-1 lg:pl-[200px]`}>
				<div className='justify-center items-center pt-[20px] xs:grid xs:pl-[50px] sm:pt-[150px] sm:inline-flex xs:w-[100%] lg:w-[90%] '>
					<div className='flex justify-center items-center  bg-white w-[100%] xs:order-last sm:-order-1 sm:border-none'>
						<span className='flex'>
							<img
								src='tabin_people_laptop2.png'
								alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
								className='h-[auto] w-[450px] sm:pr-10 '
							/>
						</span>
					</div>
					<div className='flex h-[100%] w-[100%] justify-center'>
						<div className='rs:hidden sm:inline-block h-[75%] w-[5%]'>
							<div className='block h-[50%] w-[100%] bg-[#FF6E8B]'></div>
							<div className='block h-[50%] w-[100%] bg-[#AAF0DA]'></div>
						</div>
						<div className='inline-block w-[100%] justify-center items-center bg-white sm:mt-[120px]'>
							<span className='flex justify-center w-[100%] text-[40px] font-semibold flexcenter pt-[30px] pb-[15px]'>
								Sign in
							</span>
							<div className='googlebtncontainer'>
								<GoogleLogin
									clientId={clientId}
									render={(renderProps) => (
										<button
											className='googlebtn '
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<span className='googlelogowhitebox'>
												<img
													className='googlelogo'
													src='google_logo.png'
													alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
												/>
											</span>
											LOGIN WITH GOOGLE
										</button>
									)}
									buttonText='Signin with Google'
									onSuccess={onSuccess}
									onFailure={onFailure}
									cookiePolicy={"single_host_origin"}
									isSignedIn={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
