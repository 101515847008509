import React from "react";
import {
	TbRosetteNumber1,
	TbRosetteNumber2,
	TbRosetteNumber3,
} from "react-icons/tb";
import GetStartedButton from "./GetStartedButton";

const HowItWorks = () => {
	return (
		<section className='bg-white overflow-hidden rs:pt-0 sm:pt-10 '>
			<div className='inline-container w-[91%] px-4 mx-auto'>
				<div className='max-w-xl text-center mx-auto xs:mt-14 sm:mt-0'>
					<h2 className='font-heading text-6xl tracking-tighter'>
						How Tabin works
					</h2>
				</div>
				<div className='xs:grid md:flex md:flex-wrap justify-center items-center mb-14'>
					<div className='w-full md:w-1/3 p-7 sm:ml-0'>
						<div className='grid max-w-xs'>
							<i className='flex justify-center items-center text-8xl'>
								<TbRosetteNumber1 />
							</i>
							<i className='flex flex-nowrap w-[190px] h-[120px] justify-center items-center my-4'>
								<GetStartedButton />
							</i>
							<h3 className='flex justify-center items-center mb-4 text-xl flex-wrap font-semibold tracking-tight'>
								Create a Free Account
							</h3>
							<p className='flex justify-center items-center text-gray-600 tracking-tight'>
								Create a Tabin account with a Google account.
							</p>
						</div>
					</div>
					<div className='w-full md:w-1/3 px-7'>
						<div className='max-w-xs'>
							<i className='flex flex-row text-8xl justify-center items-center'>
								<TbRosetteNumber2 />
							</i>
							<a href='https://chromewebstore.google.com/detail/tabin-schedule-your-day/aikkjpmagfploeaedmhickkcadlfndno?hl=en&authuser=0'>
								<i className='flex flex-nowrap w-[full] h-[120px] justify-center my-3 drop-shadow-sm opacity-70 hover:opacity-100 hover:drop-shadow-xl'>
									<img
										src='tabin.png'
										alt='Context Switching App to increase work productivity, single link to multiple links, take notes while attending meetings, save time and increase focus'
									/>
								</i>
							</a>
							<h3 className='flex justify-center items-center mb-4 text-xl font-semibold tracking-tight'>
								Get Tabin Extension from Chrome Store
							</h3>
							<p className='flex justify-center items-center text-gray-600 tracking-tight'>
								Pin the extension to the toolbar to make it easier to use Tabin.
							</p>
						</div>
					</div>
					<div className='w-full md:w-1/3 px-7'>
						<div className='max-w-xs'>
							<i className='flex flex-row text-8xl justify-center items-center'>
								<TbRosetteNumber3 />
							</i>
							<i className='flex flex-nowrap w-[full] h-[120px] justify-center my-3'>
								<img
									src='create_tabin.jpeg'
									alt='Context Switching App to increase work productivity, single link to multiple links, take notes while attending meetings, save time and increase focus'
								/>
							</i>

							<h3 className='flex justify-center items-center text-xl font-semibold tracking-tight my-4'>
								Start Creating Tabins
							</h3>
							<p className='flex justify-center items-center text-gray-600 tracking-tight'>
								Open websites needed for meeting, click on Tabin Icon in
								toolbar, name your Tabin and click "Create".
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HowItWorks;
