import { React, useEffect } from "react";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

const Privacy = () => {
	useEffect(() => {
		Mixpanel.track_pageview();
	}, []);
	return (
		<div className='h-[100%]'>
			<Helmet>
				<title>
					Tabin | Privacy - Context Switching Made Easy To Increase Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/privacy' />
			</Helmet>
			<iframe
				className='w-full'
				height='1000px'
				src='https://www.iubenda.com/privacy-policy/45640188/legal'
				title='Privacy Policy'
			/>
		</div>
	);
};

export default Privacy;
