import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getUserTabins } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";
const TAG = "components.Sidebar ";

function Sidebar({ tabins, onClickHandler }) {
	console.log(TAG);

	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	if (!tabins) {
		console.log("tabins is empty");
	}

	const refreshBtnClickHandler = async () => {
		Mixpanel.track("refresh btn clicked tabcase");
		localStorage.removeItem("bins");
		console.log(TAG + ".btnclickhandler getting tabins from db");
		await dispatch(getUserTabins(user.google_id));
		window.location.reload();
	};

	// useEffect(() => {
	// 	bins = JSON.parse(localStorage.getItem("bins"));
	// 	setBinsData(bins);
	// }, [bins]);
	return (
		<div className='block w-[30%]  h-auto xs:pt-[80px] xs:pl-[5px] sm:pt-[90px] sm:pl-[20px] md:pl-[40px] lg:pl-[80px] xl:pl-[140px] '>
			{" "}
			<div className='flex justify-center items-center h-[65px] w-[94%] bg-[#FCF6DA] border-solid border-2 border-black'>
				<div className='flex w-[80%] justify-center font-semibold xs:text-xl sm:text-3xl'>
					Tabins
				</div>

				<div
					className='flex w-[23%] justify-center font-semibold xs:text-xl sm:text-3xl opacity-60 webkitfilter hover:opacity-100'
					type='button'
					onClick={refreshBtnClickHandler}
				>
					<img
						src='refresh.png'
						alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
					></img>
				</div>
			</div>
			<div className='flex w-[94%] h-[auto] bg-[#AAF0DA] p-2 justify-center items-center border-solid border-[1px] border-black '>
				<ul className='p-3 m-1'>
					{tabins.map((bin) => {
						return (
							<>
								<div className='flex justify-center items-center'>
									<li
										className='flex h-[auto] bg-[#ffffff] justify-center items-center cursor-pointer changeonhover xs:p-1 xs:rounded-none xs:w-[auto] xs:m-1 sm:p-3 sm:w-[150px] sm:m-2 sm:rounded-full md:w-[225px] lg:w-[270px] xl:w-[340px]'
										key={bin._id}
										id={bin._id}
										onClick={onClickHandler}
									>
										<span
											className='flex w-[100%] justify-center items-center xs:p-1 xs:text-sm md:text-lg'
											key={bin._id}
											id={bin._id}
										>
											{bin.title.length > 20
												? bin.title.slice(0, 18) + "...."
												: bin.title}
										</span>
									</li>
								</div>
							</>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

Sidebar.propTypes = {
	tabins: PropTypes.object,
	onClickHandler: PropTypes.func,
};

export default Sidebar;
