import React from "react";

const imgages = [
	"schedule_tabin.png",
	"sync_schedule_tabins_2.png",
	"tabin_notification.png",
	"open_tabin_page.png",
];
const delay = 2500;

const Features = () => {
	const [index, setIndex] = React.useState(0);
	const timeoutRef = React.useRef(null);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	React.useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() =>
				setIndex((prevIndex) =>
					prevIndex === imgages.length - 1 ? 0 : prevIndex + 1
				),
			delay
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	/* slideshow */
	// return (
	// 	<div>
	// 		<div className='w-[50%] overflow-hidden'>
	// 			<div
	// 				className='whitespace-nowrap ease-in'
	// 				style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
	// 			>
	// 				{imgages.map((src, index) => (
	// 					<img
	// 						className='inline-block border-r-4 h-[500px] w-[100%]'
	// 						key={index}
	// 						src={`${src}`}
	// 					></img>
	// 				))}
	// 			</div>

	// 			<div className='flex justify-center'>
	// 				{imgages.map((_, idx) => (
	// 					<div
	// 						key={idx}
	// 						className={`slideshowDot ${index === idx ? " active" : ""}`}
	// 						onClick={() => {
	// 							setIndex(idx);
	// 						}}
	// 					></div>
	// 				))}
	// 			</div>
	// 		</div>
	// 		<div className='w-30%'></div>
	// 	</div>
	// );

	return (
		<div className='hidden lg:flex lg:w-[95%] justify-center m-4'>
			<div className='flex w-[65%] p-1 ml-4'>
				<img
					src='schedule_tabin.png'
					alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
				/>
			</div>
			<div className='grid justify-center w-[35%] p-4'>
				<h1 className='flex text-5xl items-end p-1 mb-6 '>Schedule Tabins</h1>
				<p className='flex justify-center w-[70%]'>
					Schedule your tabins at the begnining of each meeting. Receive a
					timely notification when the meeting arrives. Click on the
					notification to open Tabin. Attend the meeting effortlessly. Organize
					workflow and maximize productivity with Tabin.
				</p>
			</div>
		</div>
	);
};

export default Features;
