import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserTabins } from "../features/tabin/tabinSlice";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENTID;
const API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
const DISCOVERY_DOCS = [
	"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

const SignUpFlow = () => {
	const user = useSelector((state) => state.auth.user);
	const [events, setEvents] = useState([]);
	const [currentEventIndex, setCurrentEventIndex] = useState(0);
	const [finished, setFinished] = useState(false);
	const [isSignedIn, setIsSignedIn] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		function start() {
			if (!gapi.auth2.getAuthInstance()) {
				gapi.client
					.init({
						apiKey: API_KEY,
						clientId: CLIENT_ID,
						discoveryDocs: DISCOVERY_DOCS,
						scope: SCOPES,
					})
					.then(() => {
						const authInstance = gapi.auth2.getAuthInstance();
						setIsSignedIn(authInstance.isSignedIn.get());
						authInstance.isSignedIn.listen(setSigninStatus);
					})
					.catch((error) => {
						console.error("Error initializing GAPI client", error);
					});
			} else {
				const authInstance = gapi.auth2.getAuthInstance();
				setIsSignedIn(authInstance.isSignedIn.get());
				authInstance.isSignedIn.listen(setSigninStatus);
			}
		}

		gapi.load("client:auth2", start);
	}, []);

	const setSigninStatus = (isSignedIn) => {
		setIsSignedIn(isSignedIn);
		if (isSignedIn) {
			gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse()
				.access_token;
			fetchCalendarEvents();
		}
	};

	const fetchCalendarEvents = async () => {
		const accessToken = gapi.auth2
			.getAuthInstance()
			.currentUser.get()
			.getAuthResponse().access_token;
		try {
			const response = await gapi.client.calendar.events.list({
				calendarId: "primary",
				timeMin: new Date().toISOString(), // Start of the day
				timeMax: new Date(
					new Date().setDate(new Date().getDate() + 1)
				).toISOString(), // End of the day
				singleEvents: true,
				orderBy: "startTime",
			});
			const fetchedEvents = response.result.items;
			setEvents(fetchedEvents);
		} catch (error) {
			console.error("Error fetching calendar events: ", error);
		}
	};

	const handleNext = () => {
		if (currentEventIndex < events.length - 1) {
			setCurrentEventIndex(currentEventIndex + 1);
		} else {
			setFinished(true);
		}
	};

	useEffect(() => {
		if (finished) {
			dispatch(getUserTabins(user.google_id)); // Ensure `user` is defined
			const timer = setTimeout(() => {
				navigate("/mytabins");
			}, 2500);
			return () => clearTimeout(timer);
		}
	}, [finished, navigate, dispatch, user]);

	const handleAuthClick = () => {
		gapi.auth2.getAuthInstance().signIn();
	};

	const handleSignoutClick = () => {
		gapi.auth2.getAuthInstance().signOut();
	};

	return (
		<div>
			{!isSignedIn ? (
				<div>
					<h1 className='flex justify-center'>Welcome</h1>
					<div className='flex h-[40px] w-full justify-center items-center mt-10'>
						<button
							className='border-2 border-black p-3'
							onClick={handleAuthClick}
						>
							Connect Google Calendar
						</button>
					</div>
				</div>
			) : (
				<div>
					{events.length > 0 && !finished ? (
						<div>
							<p>Let's create a Tabin for your event tomorrow: </p>
							<h1 className='text-4xl'>{events[currentEventIndex].summary}</h1>
							<ul className='pt-5 list-decimal'>
								<li>Open all the URLs you need for your event</li>
								<li>
									Click on Tabin Extension icon to open it{" "}
									<img alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus' />
								</li>
								<li>Give the Tabin a name and click create</li>
							</ul>
							<button
								className='mt-10 justify-center items-center webkitfilter font-bold text-white bg-gray-400 border-2 border-black p-3 mr-2 cursor-pointer hover:bg-green-600 xs:w-[80%] md:w-[300px]'
								onClick={handleNext}
							>
								Next
							</button>
						</div>
					) : (
						finished && (
							<div>
								<p className='text-3xl m-2 p-2'>You are finished!</p>
								<p>Taking you to my tabins page</p>
							</div>
						)
					)}
					<button onClick={handleSignoutClick}>Sign Out</button>
				</div>
			)}
		</div>
	);
};

export default SignUpFlow;
