import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const TAG = "userService.";
const users = JSON.parse(localStorage.getItem("all_users"));

const initialState = {
	users: users ? users : null,
	isError: false,
	isSuccess: false,
	isLoading: false,
	isSignedIn: false,
	message: null,
};

// Get All User
export const getAllUsers = createAsyncThunk(
	"users/getAllUsers",
	async (thunkAPI) => {
		try {
			console.log(TAG + "getAllUsers");

			const token = JSON.parse(localStorage.getItem("token"));
			console.log("getAllUsers Token", token);
			return await userService.getAllUsers(token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const updateSingleUser = createAsyncThunk(
	"users/updateSingleUser",
	async (user, thunkAPI) => {
		try {
			console.log(TAG + "updateSingleUser");

			const token = JSON.parse(localStorage.getItem("token"));
			console.log("updateSingleUser Token", token);
			return await userService.updateSingleUser(user, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const deleteSingleUser = createAsyncThunk(
	"users/deleteSingleUser",
	async (user_id, thunkAPI) => {
		try {
			console.log(TAG + "deleteSingleUser");

			const token = JSON.parse(localStorage.getItem("token"));
			console.log("deleteSingleUser Token", token);
			return await userService.deleteSingleUser(user_id, token);
		} catch (error) {
			const message =
				(error.response && error.response.data & error.data.message) ||
				error.message ||
				error.toString();

			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const userSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		reset: (state) => {
			state.users = null;
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.isSignedIn = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllUsers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isSignedIn = true;
				state.users = action.payload.users;
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				state.users = null;
			})
			.addCase(updateSingleUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isSignedIn = true;
				state.users = action.payload.users;
			})
			.addCase(deleteSingleUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deleteSingleUser.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.users = state.users.filter(
					(user) => user._id !== action.payload.del_user_id
				);
			})
			.addCase(deleteSingleUser.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
			});
	},
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
